/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import Thumbor from '@common_image';
import Link from 'next/link';
import React, { useEffect, useRef } from 'react';
import { basePath, modules } from '@config';
import cx from 'classnames';
import ContainerScroll from '@common/ContainerScroll';
import { productVideoPattern } from '@helpers/video';

const ImageDetail = (props) => {
    const {
        handleClick,
        small_image,
        spesificProduct,
        urlKey,
        name,
        storeConfig = {},
        classContainer = '',
        className = '',
        isGrid,
        preload = false,
    } = props;
    const videoRefs = useRef([]);
    let defaultWidth = modules?.catalog?.productListing?.imageSize?.width;
    let defaultHeight = modules?.catalog?.productListing?.imageSize?.height;
    const withSlider = storeConfig?.pwa?.image_slider_enable === 1 || storeConfig?.pwa?.image_slider_enable === '1';

    if (typeof defaultWidth === 'string') defaultWidth = parseInt(defaultWidth, 10);
    if (typeof defaultHeight === 'string') defaultHeight = parseInt(defaultHeight, 10);

    let classImage = '!w-[144px] !h-[144px] tablet:!w-[205px] tablet:!h-[205px] desktop:!w-[250px] desktop:!h-[250px]';
    let classImageWithSlider;
    if (withSlider) {
        classImageWithSlider = '!min-w-[100%]';
    }

    const imageUrl = spesificProduct.id ? spesificProduct.image.url : small_image?.url || `${basePath}/assets/img/placeholder.png`;

    const imageAlt = small_image?.label || name;

    if (!isGrid) {
        classImage = '!w-[120px] !h-[120px] tablet:!w-[320px] tablet:!h-[320px] desktop:!w-[320px] desktop:!h-[320px]';
        if (withSlider) {
            // eslint-disable-next-line max-len
            classImageWithSlider = '!max-w-[120px] !min-w-[120px] tablet:!min-w-[320px] tablet:!max-w-[320px] desktop:!min-w-[320px] desktop:!max-w-[320px]';
        }
    }

    // gallery data
    let gallery = [];
    if (withSlider) {
        gallery = props.media_gallery;
        if (props.product_video_url && productVideoPattern.test(props.product_video_url)) {
            gallery = [
                {
                    type: 'video',
                    url: props.product_video_url,
                },
                ...gallery,
            ];
        }
    }

    // video data
    let productVideo;
    if (props.product_video_url && productVideoPattern.test(props.product_video_url)) {
        productVideo = props.product_video_url;
    }

    // handle play pause
    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.play();
            } else {
                entry.target.pause();
            }
        });
    };

    // handle video play on viewport when element show 50%
    useEffect(() => {
        const options = {
            root: null, // viewport
            rootMargin: '0px', // no margin
            threshold: 0.5, // 50% visibility
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        videoRefs.current.forEach((video) => {
            observer.observe(video);
        });

        // Clean up function
        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <>
            {withSlider && gallery?.length && gallery?.length > 1 ? (
                <ContainerScroll
                    showArrow
                    className={cx('overflow-hidden', className, '!w-full !pb-0')}
                    arrowProps={{
                        className: '!opacity-100 !pl-[0px] !pr-[0px]',
                        leftNavClassName: '!bg-[rgb(0_0_0_/_0.05)] !shadow-none',
                        rightNavClassName: '!bg-[rgb(0_0_0_/_0.05)] !shadow-none',
                    }}
                    marginSize={0}
                >
                    {gallery?.map((product, index) => (
                        <Link
                            href={{
                                pathname: '/[...slug]',
                                query: {
                                    slug: urlKey,
                                },
                            }}
                            onClick={handleClick}
                            className={cx(classImageWithSlider, 'flex justify-center')}
                            key={index}
                        >
                            {product.type && product.type === 'video' ? (
                                <div className={cx('video-wrapper', classImage)}>
                                    <video
                                        className={cx('w-full h-full object-cover', classContainer, 'overflow-hidden', className)}
                                        ref={(el) => videoRefs.current.push(el)}
                                        muted
                                        loop
                                    >
                                        <source src={product.url} />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ) : (
                                <Thumbor
                                    src={product.url}
                                    className={cx(classImage, 'overflow-hidden', className)}
                                    classContainer={cx(classImage, classContainer)}
                                    styleContainer={{ padding: 0 }}
                                    width={defaultWidth}
                                    height={defaultHeight}
                                    quality={80}
                                    alt={product.label}
                                    storeConfig={storeConfig}
                                    preload={preload}
                                    retina
                                />
                            )}
                        </Link>
                    ))}
                </ContainerScroll>
            ) : (
                <Link
                    href={{
                        pathname: '/[...slug]',
                        query: {
                            slug: urlKey,
                        },
                    }}
                    onClick={handleClick}
                    className={cx('overflow-hidden flex justify-center')}
                >
                    {productVideo ? (
                        <div className={cx('video-wrapper', classImage)}>
                            <video
                                className={cx('w-full h-full object-cover', classContainer, 'overflow-hidden', className)}
                                ref={(el) => videoRefs.current.push(el)}
                                muted
                                loop
                            >
                                <source src={productVideo} />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : (
                        <Thumbor
                            src={imageUrl}
                            className={cx(classImage, 'overflow-hidden', className)}
                            classContainer={cx(classImage, classContainer)}
                            styleContainer={{ padding: 0 }}
                            width={defaultWidth}
                            height={defaultHeight}
                            quality={80}
                            alt={imageAlt}
                            storeConfig={storeConfig}
                            preload={preload}
                            retina
                        />
                    )}
                </Link>
            )}
        </>
    );
};

export default ImageDetail;
